import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import SplashScreen from "../layout/SplashScreen";
import OrderStatus from "./OrderStatus";
import { withRouter } from "react-router-dom";
import Cart from "./Cart";
import UserPreview from "../users/UserPreview";
import OrderEventList from "./OrderEventList";
import Rating from "react-rating";
import { Link } from "react-router-dom";
import { getSupplierAmountForOrder } from "../../models/order";
import { doubleToCurrency, doubleToPercentage } from "../../utility/currencyHelper";
// import { getDateTimeString } from "../../utility/dateHelper";

class OrderDetails extends Component {
  state = {
    showPostponeModal: false,
  };

  render() {
    let { order, events, supplier, review } = this.props;

    if (!isLoaded(order, events, supplier)) return <SplashScreen />;

    if (isEmpty(order)) return <div className="container my-4">Ordine inesistente.</div>;

    // let customerInfo = (
    //   <div>
    //     <div>
    //       <i className="fas fa-map-marker-alt mr-2" />
    //       {order.customerAddress}
    //     </div>
    //     {order.useOwnDrivers === true ? (
    //       <div>Consegna al cliente per il giorno {getDateTimeString(order.preferredDeliveryTimestamp)}</div>
    //     ) : (
    //       <div>Consegna al fattorino per il giorno {getDateTimeString(order.shiftStartTime)}</div>
    //     )}
    //     {order.useCashPayment === true && order.supplierResponsibleForCash && (
    //       <div className="mt-1">
    //         <i className="fas fa-money-bill-wave mr-2" />
    //         Pagamento in contanti (contanti gestiti dalla tua attività)
    //       </div>
    //     )}
    //   </div>
    // );

    let cartComponent;

    if (order.createdBySupplier === true)
      cartComponent = (
        <div className="card card-body mb-2">Questo ordine è stato creato da gestionale, l'elenco dei prodotti non è perciò disponibile.</div>
      );
    else
      cartComponent = (
        <div className="card card-body mb-2">
          <Cart products={order.products} total={order.cartAmount} />
        </div>
      );

    let userComponents = (
      <div>
        {review && (
          <div className="card card-body mb-2">
            <h5>Recensione cliente</h5>
            <div className="d-flex align-items-center">
              <Rating
                readonly={true}
                initialRating={review.supplierRating}
                fullSymbol={<i className="fas fa-star"></i>}
                emptySymbol={<i className="far fa-star"></i>}
              />
              <div className="ml-2">{review.supplierRating.toFixed(0)}/5</div>
            </div>
            <div className="mt-1" style={{ color: "grey", fontSize: "15px" }}>
              {review.supplierReview}
            </div>
          </div>
        )}
        {/* Client that placed the order infos */}
        {/* Hide the following clients infos from the supplier */}
        {/* <div className="card card-body">
          <h5>Cliente</h5>
          <UserPreview name={order.customerName} imageUrl={order.customerImageUrl} phoneNumber={order.customerPhoneNumber} child={customerInfo} />
        </div> */}
        {order.state !== "NEW" && order.state !== "REFUSED" && order.state !== "CANCELLED" && order.useOwnDrivers !== true && (
          <div className="card card-body mt-2">
            <h5>Fattorino</h5>
            <UserPreview name={order.driverName} imageUrl={order.driverImageUrl} phoneNumber={order.driverPhoneNumber} />
          </div>
        )}
      </div>
    );

    let eventComponent = (
      <div className="mt-4">
        <OrderEventList events={events} />
      </div>
    );

    let buttonComponents = (
      <div style={{ maxWidth: 300, textAlign: "center" }}>
        <Link to="/">Le azioni sugli ordini sono state spostate nell'apposita app</Link>
      </div>
    );

    let amountsComponent = <div />;

    if (order.state === "DELIVERED")
      amountsComponent = (
        <div className="card card-body mb-2">
          <div className="d-flex justify-content-between">
            <div className="d-flex">Totale prodotti: </div>
            <div>{doubleToCurrency(order.cartAmount)}</div>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <div className="d-flex">
              <b>Totale incassato ({doubleToPercentage(order.supplierPercentage)}): </b>
            </div>
            <div>
              <b>{doubleToCurrency(getSupplierAmountForOrder(order).amountWithoutIVA)}</b>
            </div>
          </div>
        </div>
      );

    return (
      <div className="orderDetails my-3 mx-5">
        <div className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <h4>Ordine #{order.reference.id.toUpperCase()}</h4>
                <h5>
                  <OrderStatus order={order} />
                </h5>
                {order.useOwnDrivers === true ? (
                  <div>
                    <i className="fas fa-truck mr-2" />
                    Consegna gestita con fattorini propri
                  </div>
                ) : (
                  <div>
                    <i className="fas fa-truck mr-2" />
                    Consegna gestita con fattorini della piattaforma
                  </div>
                )}
              </div>
              <div className="col-md-4 col-sm-12 my-auto">{buttonComponents}</div>
            </div>
          </div>
        </div>
        <div className="d-none d-md-block">
          <div className="row">
            <div className="col-md-8">
              {cartComponent}
              {eventComponent}
            </div>
            <div className="col-md-4">
              {amountsComponent}
              {userComponents}
            </div>
          </div>
        </div>
        <div className="d-md-none">
          {amountsComponent}
          {cartComponent}
          {userComponents}
          {eventComponent}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    order: state.firestore.data.orders?.[props.match.params.orderId],
    review: state.firestore.data.reviews?.[props.match.params.orderId],
    events: state.firestore.ordered["events_" + props.match.params.orderId],
    supplierId: state.auth.supplierId,
    supplier: state.firestore.data.supplier,
  };
};

export default withRouter(
  compose(
    connect(mapStateToProps),
    firestoreConnect((props) => [
      {
        collection: "orders",
        doc: props.match.params.orderId,
      },
      {
        collection: "reviews",
        doc: props.match.params.orderId,
      },
      {
        collection: "orders",
        doc: props.match.params.orderId,
        subcollections: [{ collection: "events" }],
        orderBy: [["timestamp", "desc"]],
        storeAs: "events_" + props.match.params.orderId,
      },
      {
        collection: "suppliers",
        doc: props.supplierId,
        storeAs: "supplier",
      },
    ])
  )(OrderDetails)
);
